import React from "react";

import { useFetchOnce } from "../../shared/useFetchOnce.hook";
import { errorToList } from "../../shared/components/error-list";
import { useConfig } from "../../configProvider";

import { useAuthTokenContext } from "../auth/AuthService";

import { FileApiV1Client } from "./FileApiV1Client";


export function useProjectFiles(projectId: string|null) {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new FileApiV1Client(apiUrl['file-v1'], getToken), [apiUrl, getToken]);
    const fileListGetter = React.useCallback(async () => {
        if (projectId == null) return null;
        return client.listFiles(projectId);
    }, [projectId, client]);

    const {
        value: fileList,
        loading: fileListLoading,
        error: fileListError,
        fetch: fetchFileList,
        reset: resetFileList,
    } = useFetchOnce(fileListGetter);

    const opLoading = React.useRef(false);
    const [opError, setOpError] = React.useState<Error>(null);

    const deleteFile = React.useCallback(async (fileName: string) => {
        if (opLoading.current) return;
        opLoading.current = true;

        setOpError(null);
        resetFileList();

        try {
            await client.deleteFile(projectId, fileName);
            resetFileList();
            fetchFileList();
            setOpError(null);
        } catch (e) {
            setOpError(e);
        } finally {
            opLoading.current = false;
        }
    }, [client, projectId, opLoading, setOpError, resetFileList]);

    const uploadFile = React.useCallback(async (file: File) => {
        if (opLoading.current) return;
        opLoading.current = true;

        setOpError(null);
        resetFileList();

        try {
            await client.uploadFile(projectId, file, file.name);
            resetFileList();
            fetchFileList();
            setOpError(null);
        } catch (e) {
            setOpError(e);
        } finally {
            opLoading.current = false;
        }
    }, [client, projectId, opLoading, setOpError, resetFileList]);

    const downloadFile = React.useCallback(async (fileName: string) => {
        if (opLoading.current) return;
        opLoading.current = true;

        setOpError(null);

        try {
            const blob = await client.downloadFile(projectId, fileName);
            setOpError(null);

            browserDownloadBlob(blob, fileName);
        } catch (e) {
            setOpError(e);
        } finally {
            opLoading.current = false;
        }
    }, [client, projectId, opLoading, setOpError]);

    return React.useMemo(() => ({
        fileList,
        fileLoading: opLoading.current || fileListLoading,
        fileError: opError ?? fileListError,
        fileErrorList: errorToList(opError, 'File') ?? errorToList(fileListError, 'File'),
        fetchFileList,
        resetFileList,
        deleteFile,
        uploadFile,
        downloadFile,
    }), [
        fileList,
        fileListLoading,
        fileListError,
        opLoading,
        opError,
        fetchFileList,
        resetFileList,
        deleteFile,
        uploadFile,
        downloadFile,
    ]);
}

function browserDownloadBlob(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute('download', fileName);
    link.setAttribute('data-cy', 'file-download');

    document.body.appendChild(link);
    link.click();

    if ((window as any).Cypress) {
        // Do not attempt to actually download the file in test.
        // Just leave the anchor in there. Ensure your code doesn't
        // automatically remove it either.
        console.log("Leave the href here for cypress.");
        return;
    }

    link.parentNode.removeChild(link);
}
