import * as React from "react";

import { IConfig } from "./types";
import { ConfigApiV1Client } from "./ConfigApiV1Client";
import { useFetchOnce } from "../../shared/useFetchOnce.hook";


interface ConfigContextType {
    config?: IConfig;
    configError?: Error;
    configLoading: boolean;
}
const ConfigContext = React.createContext<ConfigContextType>(null);

export const ConfigContextProvider: React.FC<{ url: string; }> = ({ children, url }) => {
    const client = React.useMemo(() => new ConfigApiV1Client(url), [url]);
    const configGetter = React.useCallback(() => {
        console.debug(`[ConfigContextProvider] Loading config for ${client._baseUrl}`)
        return client.getConfig()
    }, [client]);

    const {
        value: config,
        error: configError,
        loading: configLoading,
        fetch: fetchConfig,
        reset: resetConfig,
    } = useFetchOnce(configGetter);

    React.useEffect(() => {
        fetchConfig();
        return () => resetConfig();
    }, [fetchConfig, resetConfig]);

    const value = React.useMemo(() => ({
        config,
        configError,
        configLoading,
    }), [
        config,
        configError,
        configLoading,
    ])

    return React.createElement(ConfigContext.Provider, { value }, children);
}

export function useConfigV2() {
    return React.useContext(ConfigContext);
}
