import * as React from 'react';
import { Grid } from 'semantic-ui-react';

import { useConfig } from '../../configProvider';
import { useCurrency, useLocalization } from '../../localization/hook';
import { useAuthTokenContext } from "../auth/AuthService";

import "./SimulatorPage.scss";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'app-simulation': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

export const SimulatorPage: React.FC<{}> = () => {
    const { bkSimulatorUrl } = useConfig();
    const { getToken, isTokenExpired } = useAuthTokenContext();

    const [frameLoaded, setFrameLoaded] = React.useState(false);

    const iframeRef = React.useRef<HTMLIFrameElement>(null);

    function postMessage(frame: HTMLIFrameElement|null, msg: any) {
        if (!frame) {
            return false;
        }
        frame.contentWindow.postMessage(msg, '*');
        return true;
    }
    
    function loadSimulatorApp() {
        getToken().then(token => postMessage(iframeRef.current, { method: 'init', token }));
    
        return setInterval(updateSimulatorAppToken, 1000);
    }
    
    function updateSimulatorAppToken() {
        if (isTokenExpired(20)) {
            getToken().then(token => postMessage(iframeRef.current, { method: 'updateToken', token }));
        }
    }

    const onFrameLoad = () => {
        setFrameLoaded(true);
    }

    // Load App
    React.useEffect(() => {
        if (!frameLoaded) return;

        const interval = loadSimulatorApp();

        return () => {
            interval && clearInterval(interval);
        }
    }, [frameLoaded, iframeRef.current]);

    // Language
    const { language, localize } = useLocalization();
    React.useEffect(() => {
        if (!frameLoaded) return;

        postMessage(iframeRef.current, { method: 'setLanguage', language });
    }, [frameLoaded, language]);

    // Currency
    const currency = useCurrency();
    React.useEffect(() => {
        if (!frameLoaded) return;

        postMessage(iframeRef.current, { method: 'setCurrency', currency })
    }, [frameLoaded, currency]);

    // Render
    return <>
        <Grid columns={1} padded><Grid.Row><Grid.Column>
            <div className="simulation-container">
                <iframe title="frame" className="simulator-frame" frameBorder="0"
                    ref={iframeRef} onLoad={onFrameLoad} src={bkSimulatorUrl}>
                </iframe>
            </div>
        </Grid.Column></Grid.Row></Grid>
    </>;
};
