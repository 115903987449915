import React from "react";

import { IUser } from "../auth/IUser";
import { IProject } from "../project/ProjectModels";
import { UserApiV1Client } from "../auth/UserApiV1Client";


export function useFreshworksWidgetPrefillUser(user: IUser) {
    React.useEffect(() => {
        if (UserApiV1Client.ValidateIUser(user) && window.FreshworksWidget) {
            window.FreshworksWidget('identify', 'ticketForm', {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
            });

            return () => {
                window.FreshworksWidget?.('logout');
            };
        }
    }, [window.FreshworksWidget, user]);

    const company = user?.company;
    React.useEffect(() => {
        if (UserApiV1Client.ValidateICompany(company) && window.FreshworksWidget) {
            window.FreshworksWidget('prefill', 'ticketForm', { custom_fields: {
                cf_kundenfirma: `${company.name} (ID ${company.id})`,
            }});

            return () => {
                window.FreshworksWidget?.('prefill', 'ticketForm', { custom_fields: {
                    cf_kundenfirma: '',
                }});
            };
        }
    }, [window.FreshworksWidget, company?.id, company?.name]);
}

export function useFreshworksWidgetPrefillProjectId(projectId: IProject['id'] | 'new') {
    React.useEffect(() => {
        if (window.FreshworksWidget && !!projectId) {
            window.FreshworksWidget('prefill', 'ticketForm', {
                subject: projectId === 'new' ? 'Neues Projekt: ' : `Projekt ${projectId}: `,
                custom_fields: {
                    'cf_projekt_id': projectId,
                },
            });

            return () => {
                window.FreshworksWidget?.('prefill', 'ticketForm', { 'subject': '' });
            };
        }
    }, [window.FreshworksWidget, projectId]);
}

export function useFreshworksOpenWithPrefill(): null|((fields: any) => void) {
    return React.useMemo(() => {
        if (!window.FreshworksWidget) {
            return null; // widget not available, return nothing
        }

        return (fields: any) => {
            window.FreshworksWidget?.('prefill', 'ticketForm', {
                ...fields
            });
            window.FreshworksWidget?.('open', 'ticketForm');
        };
    }, [window.FreshworksWidget]);
}

declare global {
    interface Window {
        FreshworksWidget?: (
            action: 'open'|'close'|'destroy'|'boot'|'identify'|'logout'|'hide'|'show'|'disable'|'enable'/*??<--not documented??*/|'prefill',
            target?: 'ticketForm'|'article'|'launcher',
            ...args: any[]
          ) => unknown;
    }
}
