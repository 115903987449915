import * as React from 'react';

import { useConfig } from '../../configProvider';
import { useFetchOnce } from '../../shared/useFetchOnce.hook';
import { useAuthTokenContext } from '../auth/AuthService';

import { SettingsApiV1Client } from './SettingsApiV1Client';
import { ISettings } from './SettingsModels';


interface SettingsContextType {
    settings: ISettings;
    settingsError: Error|null;
    settingsLoading: boolean;
    fetchSettings: () => void;
}
const SettingsContext = React.createContext<SettingsContextType>(null);

export const SettingsContextProvider: React.FC<{}> = ({ children }) => {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new SettingsApiV1Client(apiUrl['settings-v1'], getToken), [apiUrl, getToken]);
    const settingsGetter = React.useCallback(() => client.getSettings(), [client]);

    const {
        value: settings,
        error: settingsError,
        loading: settingsLoading,
        fetch: fetchSettings,
    } = useFetchOnce(settingsGetter);

    const value = React.useMemo(() => ({
        settings,
        settingsError,
        settingsLoading,
        fetchSettings,
    }), [
        settings,
        settingsError,
        settingsLoading,
        fetchSettings,
    ]);

    return React.createElement(SettingsContext.Provider, { value }, children);
}

export function useSettings() {
    return React.useContext(SettingsContext);
}
