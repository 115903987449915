import * as React from 'react';
import {Icon, Modal, Tab, Table, List, Label} from 'semantic-ui-react';

import { useLocalization } from '../localization/hook';
import { LanguageKey, forLanguage } from '../localization/LocalizationService';

import { DataProtectionDeclaration } from './documents/DataProtectionDeclaration';
import { LegalNotice } from './documents/LegalNotice';


import './TermsAndConditions.scss';

export const TermsAndConditionsModal: React.FC<{ className?: string }> = ({ className }) => {
    const { localizeEnum } = useLocalization();

    const [open, setOpen] = React.useState(false);
    const [targetTab, setTargetTab] = React.useState<TermsAndConditionsTabKey|null>(null);

    const onClose = React.useCallback(() => {
        setOpen(false);
        setTargetTab(null);
    }, [setOpen]);

    const onTriggerClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setTargetTab(e.currentTarget.dataset['link'] as TermsAndConditionsTabKey);
        setOpen(true);
    }, [setTargetTab, setOpen]);

    return (
        <Modal open={open}
        onClose={onClose}
        closeIcon
        className={['TermsAndConditionsModal', className].join('')}
        trigger={<>{['termsandconditions', 'data_protection_declaration', 'legal_notice'].map(key => (
            <a href="#" className="item" onClick={onTriggerClick} key={key} data-link={key}>{localizeEnum(key, 'link')}</a>
        ))}</>}>
            <Modal.Content>
                <TermsAndConditionsTabs defaultTab={targetTab}/>
            </Modal.Content>
        </Modal>
    );
};

type CompanyUrls = { company: string, country: string, urls: { type: string, language: LanguageKey, url: string }[], }[];
const termsAndConditions: CompanyUrls = [
    { company: 'D+H Deutschland GmbH', country: 'de', urls: [
        { type: 'termsofservice', language: 'de', url: 'https://www.bluekit.de/agb' },
        { type: 'termsofinstallation', language: 'de', url: '/static/BlueKit_DH_Deutschland_Montagebedingungen_2024_02_20.pdf' },
    ]},
    { company: 'D+H Österreich GmbH', country: 'at', urls: [
        { type: 'termsofservice', language: 'de', url: 'https://www.dh-partner.com/Website/D-H-Oesterreich/Downloads/AGB_DH-Oesterreich-GmbH.pdf' },
    ]},
    { company: 'D+H Mechatronic AG', country: 'int', urls: [
        { type: 'termsofsaleanddelivery', language: 'de', url: 'https://www.dh-partner.com/group/de/meta/rechtliches/Verkaufs-und-Lieferbedingungen' },
        { type: 'termsofsaleanddelivery', language: 'en', url: 'https://www.dh-partner.com/group/en/meta/legal/Terms-of-sale-and-delivery' },
    ]}
];

type TermsAndConditionsTabKey = 'termsandconditions'|'data_protection_declaration'|'legal_notice';
const TermsAndConditionsTabs: React.FC<{ defaultTab?: TermsAndConditionsTabKey; }> = ({ defaultTab }) => {
    const { localize } = useLocalization();

    const defaultActiveTabIndex = React.useMemo(() => {
        switch (defaultTab) {
            case 'termsandconditions': return 0;
            case 'data_protection_declaration': return 1;
            case 'legal_notice': return 2;
            default: return null;
        }
    }, [defaultTab]);

    return (
        <Tab renderActiveOnly menu={{ pointing: true }} panes={[
            { menuItem: localize('link.termsandconditions'), render: () => <Tab.Pane>
                <TermsAndConditionsLinksTable/>
            </Tab.Pane>},
            { menuItem: localize('link.data_protection_declaration'), render: () => <Tab.Pane>
                <DataProtectionDeclaration/>
            </Tab.Pane>},
            { menuItem: localize('link.legal_notice'), render: () => <Tab.Pane>
                <LegalNotice/>
            </Tab.Pane>},
        ]} defaultActiveIndex={defaultActiveTabIndex}/>
    );
};

const TermsAndConditionsLinksTable: React.FC<{}> = () => {
    const { language, localizeCountry, localizeEnum } = useLocalization();

    return (
        <Table basic='very'>
            <Table.Body>
                {termsAndConditions.map(entry => (<Table.Row key={entry.company}>
                    <Table.Cell width={4}>{entry.company}</Table.Cell>
                    <Table.Cell width={2}>{entry.country === 'int' ? 'International' : localizeCountry(entry.country)}</Table.Cell>
                    <Table.Cell width={10}><List>{entry.urls.map(({ type, language: linklang, url }) => (
                        <List.Item key={type + linklang}>
                            <List.Content floated='right'>
                                <Label size='tiny' color='black' basic>{localizeEnum(linklang, 'language')}</Label>
                            </List.Content>
                            <Icon name='external alternate'/>
                            <List.Content>
                                <List.Header><a target="_blank" rel="noopener noreferrer" href={url}>
                                    {forLanguage(linklang).localizeEnum(type, 'link')}
                                </a></List.Header>
                                {linklang !== language && (<List.Description>
                                    {localizeEnum(type, 'link')}
                                </List.Description>)}
                            </List.Content>
                        </List.Item>
                    ))}</List></Table.Cell>
                </Table.Row>))}
            </Table.Body>
        </Table>
    );
};
