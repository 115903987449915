import * as React from "react";
import Keycloak from "keycloak-js";
import { useConfig } from "../../configProvider";
import { useConfigV2 } from "../config/Config.hook";


interface AuthContextType {
    /** Reset the session */
    reset: () => void;
    /** The user is authenticated */
    isAuthenticated: boolean;
    /** The user is authenticated via an E2E Token */
    isE2EAuthenticated: boolean;
    /** The user is authenticated via KeyCloak SSO */
    isKeycloakAuthenticated: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null);

export const AuthContextProvider: React.FC<{}> = ({ children }) => {
    const config = useConfig();

    // E2E Token Authentication
    const [isE2EAuthenticated, setIsE2EAuthenticated] = React.useState(false);
    React.useEffect(() => {
        if (!config.enableE2EToken) return;
        setIsE2EAuthenticated(!!localStorage.getItem('e2e_idtoken'));
    }, [config.enableE2EToken, setIsE2EAuthenticated]);

    // KeyCloak SSO Authentication
    const { config: { keycloakPublicBaseUrl } } = useConfigV2();
    const [isKeycloakAuthenticated, setKeycloakAuthenticated] = React.useState(false);
    React.useEffect(() => {
        if (config.disableKeyCloak) return;

        const kc = window.keyCloak = new Keycloak({
            realm: 'DH',
            clientId: 'bluekit-connect',
            url: keycloakPublicBaseUrl,
        });

        kc.init({
            enableLogging: false,
            onLoad: 'login-required',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        }).then((authenticated: boolean) => {
            setKeycloakAuthenticated(authenticated);
        });

        return () => {
            kc.clearToken();
            window.keyCloak = undefined;
            setKeycloakAuthenticated(false);
        };
    }, [keycloakPublicBaseUrl, config.disableKeyCloak, setKeycloakAuthenticated, window.location.origin]);

    const reset = React.useCallback(() => {
        if (config.disableKeyCloak) return;

        window.keyCloak?.clearToken();
        window.keyCloak?.logout();
    }, [config.disableKeyCloak]);

    const value = {
        reset,
        isAuthenticated: isE2EAuthenticated || isKeycloakAuthenticated,
        isE2EAuthenticated,
        isKeycloakAuthenticated,
    };

    return React.createElement(AuthContext.Provider, { value }, children);
};

export function useAuthenticated() {
    return React.useContext(AuthContext);
}
