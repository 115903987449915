import { all } from 'redux-saga/effects';

import { saga as calculationSaga } from '../features/calculation/CalculationSaga';
import { projectApi } from '../features/calculation/ProjectApi.redux';


export default function* rootSaga(dispatch: any) {
    return yield all([
        calculationSaga(),
        projectApi.saga(),
    ]);
}
