import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isObjectWithProperties } from "../api/simple-validators";

import { IConfig } from "./types";


/** @see `DH.BlueKitConnect.Backend.Features.Config.ConfigController` */
export class ConfigApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getConfig(): Promise<IConfig> {
        return await this._fetchJson(
            this._getUrl(),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            ConfigApiV1Client.ValidateIConfig
        );
    }

    public static ValidateIConfig(v: unknown): v is IConfig {
        return isObjectWithProperties('keycloakPublicBaseUrl')(v) &&
            typeof v.keycloakPublicBaseUrl === 'string';
    }
}
