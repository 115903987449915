import * as React from 'react';
import { Message } from 'semantic-ui-react';

import { IUser } from '../features/auth/IUser';
import { useUser } from '../features/auth/User.hook';
import { useLocalization } from '../localization/hook';
import { Spinner, SpinnerOpCode } from '../shared/components/Spinner';


export interface AccessControlProps {
    checkFn: (user: IUser) => boolean;
}

export const AccessControl: React.FC<AccessControlProps> = (props) => {
    const { localize } = useLocalization();
    const { user, userLoading, userError } = useUser();

    const accessGranted = React.useMemo(() => !!user && props.checkFn(user), [props.checkFn, user]);

    if (!user || (userError && userLoading)) {
        return <Spinner segment operation={SpinnerOpCode.AC_PENDING} loading={userLoading} error={userError}/>
    }

    if (!accessGranted) {
        return <Message className='message-account-notActivated' negative content={localize('account.message.notActivated')}/>;
    }

    return <>{props.children}</>;
};
