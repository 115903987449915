import { getAIOBasicSetVent } from "../CalculationService/AIOBasicSet";
import { getAvailableVentilationComponentsForAIOBasicSet } from "../CalculationService/VentilationComponents";
import { INSTALLATION_POSITION } from "../../project/lookup";
import { PRODUCT_CATEGORY, PRODUCT_KEY } from "../../product/lookup";

import { RuleKey, RootBuilderRule } from "./types";
import ventCompFlow from "./vent-comp.partial";


/**
 * Rules for AIO Basic Sets
 * @note: rules defined according to document: Abhängigkeiten im Kalkulationstool.pdf
 *        as of Feb. 2024, this document has not been seen by the current development team.
 */
const completeSetFlow: Record<RuleKey, RootBuilderRule> = {
    [PRODUCT_CATEGORY.SETAIOB + '-vent']: {
        stage: 'config',
        when: { ctx: 'basicValues', key: 'aioBasicSet' },
        exec: ({ basicValues: { projectType }, totalLiftArea, addProductByKey }) => {
            const vent = getAIOBasicSetVent(projectType, totalLiftArea);
            if (vent) {
                addProductByKey(vent.productKey as PRODUCT_KEY, 1);
            }
        }
    },

    [PRODUCT_CATEGORY.SETAIOB + '-noOfInterfaceAdapters']: {
        stage: 'config',
        when: [
            { ctx: 'basicValues', key: 'aioBasicSet' },
            { value: { ctx: 'basicValues', key: 'noOfInterfaceAdapters' }, '>': { constant: 0 } },
        ],
        exec: ({ basicValues: { noOfInterfaceAdapters }, addProductByKey }) =>
            addProductByKey(PRODUCT_KEY.eightPinInterfaceAdapterSet, noOfInterfaceAdapters),
    },

    [PRODUCT_CATEGORY.SETAIOB + '-weathershelter']: {
        // ALAS weathershelter for AIO Basic Sets
        stage: 'config',
        when: [
            { ctx: 'basicValues', key: 'aioBasicSet' },
            { ctx: 'basicValues', key: 'weathershelter' },
            { value: { ctx: 'basicValues', key: 'installationPosition' }, '==': { constant: INSTALLATION_POSITION.VERTICAL }},
        ],
        exec: ({ basicValues: { projectType, installationPosition }, totalLiftArea, productData, addProductById }) => {
            const weatherProtectionComponents = getAvailableVentilationComponentsForAIOBasicSet(
                projectType, totalLiftArea, installationPosition, productData
            ).weatherProtectionComponents;
            addProductById(weatherProtectionComponents[0].id, 1);
        },
    },

    ...ventCompFlow,
};

export default completeSetFlow;
