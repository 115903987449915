import { CurrencyKey, LanguageKey } from "./localization/LocalizationService";


// @TODO: Fetch these from the backend
const simDomains = {
    ".stage.mchst.net": "https://bksim.stage.mchst.net",
    ".prod1.mchst.net": "https://bksim.prod1.mchst.net",
    "localhost": "http://localhost:3002",
};
function getSimulatorURL() {
    const hostname = Object.entries(simDomains).find(([k, _]) => window.location.hostname.endsWith(k))?.[1] ?? "https://simulator.bluekit.de";
    return `${hostname}/assets/framed.html?t=${Date.now()}`;
}

class ConfigProvider {
    // Localization
    public defaultLanguage: LanguageKey = 'de';
    public manuallySetLanguageLocalStorageKey = 'bk.connect.manuallySetLanguage';
    public manuallySetLanguageLocalStorageTTL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    public productDataLanguages: LanguageKey[] = ['de', 'en'];

    public defaultCurrency: CurrencyKey = 'EUR';
    public enableCurrencySelect = false;
    public manuallySetCurrencyLocalStorageKey = 'bk.connect.manuallySetCurrency';
    public manuallySetCurrencyLocalStorageTTL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

    // Feature Flags
    public disableKeyCloak: boolean = false;
    public enableE2EToken: boolean = false;

    public disableCtrlKeyFastDelete: boolean = true;

    // Endpoint URLs
    private backendUrl: string = window.location.origin;
    public apiUrl = {
        'settings-v1':       this.backendUrl + '/api/settings',
        'user-v1':           this.backendUrl + '/api/user',
        'product-v1':        this.backendUrl + '/api/product',
        'project-v1':        this.backendUrl + '/api/project-v1',
        'project-export-v1': this.backendUrl + '/api/project/export',
        'file-v1':           this.backendUrl + '/api/file',
        'calculation-v1':    this.backendUrl + '/api/calculation-v1',
        'reporting-v1':      this.backendUrl + '/api/reporting-v1',
    };

    // External Links
    public bkSimulatorUrl: string = getSimulatorURL();
}

/** @deprecated Use `useConfig()` hook instead */
export const configProvider = new ConfigProvider();

export function useConfig() {
    return configProvider;
}

export type Config = ReturnType<typeof useConfig>;
