/** @see `DH.BlueKitConnect.Product.ProductTypeIds` */
export enum PRODUCT_TYPE {
    ACCESSORIES = 'ACCESSORIES',
    CABLING = 'CABLING',
    CALIPER = 'CALIPER',
    CENTRALUNIT = 'CENTRALUNIT',
    AIOBSET = 'AIOBSET',
    SDLF1 = 'SDLF1',
    LST = 'LST',
    NRWG = 'NRWG',
    POINTDETECTOR = 'POINTDETECTOR',
    SMOKEASPIRATION = 'SMOKEASPIRATION',
    WEATHERPROTECTION = 'WEATHERPROTECTION',
}

/** @see `DH.BlueKitConnect.Product.ProductGroupIds` */
export enum PRODUCT_GROUP {
    ASSEMMATERIAL = 'ASSEMMATERIAL',
    BKSYSCTRL = 'BKSYSCTRL',
    BRACKET = 'BRACKET',
    CABLINGCOMP = 'CABLINGCOMP',
    DACHHAU = 'DACHHAU',
    JK180 = 'JK180',
    JK180DB = 'JK180DB',
    JK190 = 'JK190',
    JK190DB = 'JK190DB',
    NKSL = 'NKSL',
    LAMHAU = 'LAMHAU',
    NONSTANDARDCOMPONENTS = 'NONSTANDARDCOMPONENTS',
    SETAIOB = 'SETAIOB',
    SLIDEFLAP = 'SLIDEFLAP',
    POINTDETECTOR = 'POINTDETECTOR',
    SDLF1 = 'SDLF1',
    LIFTBEAM = 'LIFTBEAM',
    RAS = 'RAS',
    SMOKEDECTCOMP = 'SMOKEDECTCOMP',
    THERMOFLAP = 'THERMOFLAP',
    THERMOS9 = 'THERMOS9',
    THERMOTAIRMO = 'THERMOTAIRMO',
    VENTTRIGGER = 'VENTTRIGGER',
    LST = 'LST',
    ALAS = 'ALAS',
}

/** @see `DH.BlueKitConnect.Product.ProductCategoryIds` */
export enum PRODUCT_CATEGORY {
    ASSEMMATERIAL = 'ASSEMMATERIAL',
    BKSYSCTRL = 'BKSYSCTRL',
    CABLINGCOMP = 'CABLINGCOMP',
    NONSTANDARDCOMPONENTS = 'NONSTANDARDCOMPONENTS',
    SETAIOB = 'SETAIOB',
    SMOKEDECTCOMP = 'SMOKEDECTCOMP',
    VENTCOMP = 'VENTCOMP',
    VENTCTRLCOMP = 'VENTCTRLCOMP',
}

// Product Keys
export enum SET_PRODUCT_KEY {
    PD_12 = "AIO-BASIC-PD-12-SET",
    PD_24 = "AIO-BASIC-PD-24-SET",
    PD_36 = "AIO-BASIC-PD-36-SET",
    PD_48 = "AIO-BASIC-PD-48-SET",
    SDLF_50 = "AIO-BASIC-SD-L-F1-50-SET",
    SDLF_100 = "AIO-BASIC-SD-L-F1-100-SET"
}

export enum PRODUCT_KEY {

    // Central Unit
    BK_AIO_CENTRAL_UNIT = 'bk-aio-centralUnit',
    AIO_BASIC_CENTRAL_UNIT = 'aio-basic-centralUnit',

    // Cables
    CABLE_12_M = 'cable12m',
    CABLE_4_M = 'cable4m',
    CABLE_6_M = 'cable6m',

    // Smoke Detectors
    PD_RJ_AIO_END = 'PD-RJ-AIO-End',
    PD_RJ_AIO_PG = 'PD-RJ-AIO-P&G',
    MAIN_LEVEL_SMOKE_DETECTOR = 'mainLevelSmokeDetector',
    RAUCHMELDER_MASCHINENRAUM = 'Rauchmelder-Maschinenraum',

    // LST
    LST_CO2_V3_AB = 'lst-co2-v3-ab',
    REPEATER_CONNECT_MODULE = 'repeaterConnectModule',
    REPEATER_END_MODULE = 'repeaterEndModule',

    // SD-L-F1
    SD_L_F_1_RJ_AIO_50_END = 'SD-L-F1-RJ-AIO-50-End',
    SD_L_F_1_RJ_AIO_120_END = 'SD-L-F1-RJ-AIO-120-End',

    // RAS
    RJ_RAS_MICROS_SENS_42_M = 'RJ-RAS-Micros-Sens-42m',
    RJ_RAS_PRO_SENS_200_M = 'RJ-RAS-Pro-Sens-200m',
    RJ_RAS_ACCESSORIES = 'RJ-RAS-Accessories',
    FILTER_RJ_FEIN_RAS = 'Filter-RJ-Fein-RAS',

    // Smoke Switches
    RT_45_LRJ = 'rt-45l-rj',
    RT_45_RJ = 'rt-45-rj',

    // Accessories and misc.
    ADDITIONAL_THERMOSTAT = 'additionalThermostat',
    SIREN_WITH_KEY_SWITCH = 'sirenWithKeySwitch',
    VENTILATION_TIMER = 'ventilationTimer',
    HUMIDITY_SENSOR = 'humiditySensor',
    KEY_SWITCH_LED = 'keySwitchLED',
    SMOKE_TEST_SPRAY = 'SmokeTestSpray',
    BATTERY_PACK = 'batteryPack',
    eightPinInterfaceAdapterSet = '8pol-interface-adapter-set',
    RJ_45_SPLITTER = 'rj-45-splitter',
    FM_2_M = 'fm-2m',
    ASSEMBLY_SET_BLUE_KIT_LAIO = 'AssemblySetBlueKitLAIO',
}

export enum CHARACTERISTIC_KEY {
    HORIZONTAL_INSTALLATION = "HORIZONTAL_INSTALLATION",
    VERTICAL_INSTALLATION = "VERTICAL_INSTALLATION",

    NEWBUILDING_INSTALLATION = "NEWBUILDING_INSTALLATION",
    MODERNIZED_INSTALLATION = "MODERNIZED_INSTALLATION",

    WEATHERPROTECTION_BUILTIN = "WEATHERPROTECTION_BUILTIN",
    WEATHERPROTECTION_COMPATIBLE = "WEATHERPROTECTION_COMPATIBLE",

    LOWENERGY = "LOWENERGY",
    abZ = "abZ",
    QUIET = "QUIET",

    FLUSH_MOUNTING = "FLUSH_MOUNTING",
    SURFACE_MOUNTING = "SURFACE_MOUNTING",

    WITH_SPRING_RETURN = "WITH_SPRING_RETURN",
};
