import { SYSTEM, MACHINE_ROOM, LIFT_STATUS_DETECTION, SMOKE_DETECTION, INSTALLATION_POSITION, PROJECT_TYPE } from "../../project/lookup";
import { PRODUCT_CATEGORY, PRODUCT_KEY, PRODUCT_GROUP, PRODUCT_TYPE, CHARACTERISTIC_KEY } from "../../product/lookup";

import { getProductById } from "../CalculationService";
import { getLSTRepeaterCount } from "../CalculationService/LiftStatusDetection";
import { getSystem } from "../CalculationService/System";

import { RuleKey, RootBuilderRule } from "./types";
import ventCompFlow from "./vent-comp.partial";


/**
 * Rules for regular BlueKit system configuration
 * @note: rules defined according to document: Abhängigkeiten im Kalkulationstool.pdf
 *        as of Feb. 2024, this document has not been seen by the current development team.
 */
const defaultFlow: Record<RuleKey, RootBuilderRule> = {
    [PRODUCT_CATEGORY.BKSYSCTRL]: {
        stage: 'config',
        selectOneBy: ({ basicValues, configValues }) => getSystem(basicValues, configValues),
        sealed: true,
        options: {
            [SYSTEM.AIO_BASIC]: {
                exec: { builtin: { name: 'addProductByKey', args: [PRODUCT_KEY.AIO_BASIC_CENTRAL_UNIT, 1] }}
            },
            [SYSTEM.L_AIO]: {
                exec: { builtin: { name: 'addProductByKey', args: [PRODUCT_KEY.BK_AIO_CENTRAL_UNIT, 1] }},
            }
        },
    },
    // Rules for Assembly Set, Central Unit, Battery Pack, Additional Thermostat
    [PRODUCT_KEY.ASSEMBLY_SET_BLUE_KIT_LAIO]: {
        stage: 'config',
        exec: { builtin: { name: 'addProductByKey', args: [PRODUCT_KEY.ASSEMBLY_SET_BLUE_KIT_LAIO, 1] }},
    },
    // Rule 4.
    [PRODUCT_KEY.ADDITIONAL_THERMOSTAT]: {
        stage: 'config',
        when: { or: [
            { ctx: 'configValues', key: 'additionalThermostat' },
            { value: { ctx: 'basicValues', key: 'machineRoom' }, '==': { constant: MACHINE_ROOM.DOWN } },
        ]},
        exec: { builtin: { name: 'addProductByKey', args: [PRODUCT_KEY.ADDITIONAL_THERMOSTAT, 1] }},
    },

    // Rules for Lift Status Detection
    [PRODUCT_GROUP.LST]: {
        stage: 'config',
        selectOneBy: { ctx: 'configValues', key: 'liftStatusDetection' },
        sealed: true,
        options: {
            [LIFT_STATUS_DETECTION.LST_CO2_V3]: {
                exec: ({ basicValues: { shaftHeight, machineRoom, noOfElevators }, addProductByKey }) => {
                    // Rule 3.
                    addProductByKey(PRODUCT_KEY.LST_CO2_V3_AB, noOfElevators);

                    // Rules 6 & 7.
                    const repeaterCount = getLSTRepeaterCount({ shaftHeight, machineRoom });
                    if (repeaterCount > 0) {
                        // One repeater is the end module, all others are connect modules
                        addProductByKey(PRODUCT_KEY.REPEATER_END_MODULE, 1);
                        addProductByKey(PRODUCT_KEY.REPEATER_CONNECT_MODULE, repeaterCount - 1);
                    }
                },
            },
            [LIFT_STATUS_DETECTION.FAULTALARMCONTACT]: { empty: true },
        },
    },

    // Rules for Smoke Detection Methods in shaft
    [PRODUCT_CATEGORY.SMOKEDECTCOMP]: {
        stage: 'config',
        selectOneBy: { ctx: 'configValues', key: 'smokeDetection' },
        sealed: true,
        options: {
            [SMOKE_DETECTION.RAS]: {
                exec: ({ basicValues: { shaftHeight, noOfElevators }, addProductByKey }) => {
                    // Rules 9 & 10.
                    const productKey = shaftHeight <= 42_000
                        ? PRODUCT_KEY.RJ_RAS_MICROS_SENS_42_M
                        : PRODUCT_KEY.RJ_RAS_PRO_SENS_200_M;
                    const quantity = noOfElevators >= 3 ? 2 : 1;

                    addProductByKey(productKey, quantity);

                    // RAS Accessories
                    // Previously in rule 'RAS-Components'
                    addProductByKey(PRODUCT_KEY.RJ_RAS_ACCESSORIES, quantity);
                    addProductByKey(PRODUCT_KEY.FILTER_RJ_FEIN_RAS, quantity);

                    // Pipe height is shaft height rounded up to a multiple of 2500,
                    // limited to no less than 5000 and no more than 75000.
                    const HEIGHT_STEP =  2_500;
                    const HEIGHT_MIN  =  5_000;
                    const HEIGHT_MAX  = 75_000;

                    const pipeHeight = Math.min(Math.max(Math.ceil(shaftHeight / HEIGHT_STEP) * HEIGHT_STEP, HEIGHT_MIN), HEIGHT_MAX);

                    addProductByKey(`RJ-RAS-Pipe-${pipeHeight}` as PRODUCT_KEY, quantity);
                }
            },
            [SMOKE_DETECTION.SDLF1]: {
                when: { and: [
                    { value: { ctx: 'basicValues' , key: 'shaftHeight' }, '>':  { constant: 8_000 }},
                    { value: { ctx: 'basicValues' , key: 'shaftHeight' }, '<=': { constant: 120_000 }},
                ]},
                exec: ({ basicValues: { shaftHeight, noOfElevators }, addProductByKey }) => {
                    // Rules 11 & 12.

                    const productKey = shaftHeight <= 50_000
                        ? PRODUCT_KEY.SD_L_F_1_RJ_AIO_50_END
                        : PRODUCT_KEY.SD_L_F_1_RJ_AIO_120_END;
                    const quantity = noOfElevators >= 3 ? 2 : 1;
                    
                    addProductByKey(productKey, quantity);
                }
            },
            [SMOKE_DETECTION.POINTDETECTOR]: {
                when: { value: { ctx: 'basicValues', key: 'noOfElevators' }, '<=': { constant: 2 }},
                exec: ({ basicValues: { shaftHeight }, addProductByKey }) => {
                    function getPDQuantityFromShaftHeight(shaftHeight: number) {
                        /** Maximum distance between point detectors */
                        const PD_DISTANCE = 12_000;
                        /* For every 12m, add a point detector (P&G): 12m, 24m, 36m, ... */
                        return Math.max(1, Math.ceil(shaftHeight / PD_DISTANCE));
                    }

                    const quantity = getPDQuantityFromShaftHeight(shaftHeight); // always >= 1
                    addProductByKey(PRODUCT_KEY.PD_RJ_AIO_PG, quantity - 1);

                    // Always add one point detector (End)
                    addProductByKey(PRODUCT_KEY.PD_RJ_AIO_END, 1);
                }
            },
            [SMOKE_DETECTION.NONE]: { empty: true }
        },
    },

    // Rules for separate Smoke Detectors in Machine Room or Main Level
    'Rauchmelder-Maschinenraum': {
        stage: 'config',
        selectOneBy: ({ basicValues, configValues }) => getSystem(basicValues, configValues),
        sealed: true,
        options: {
            [SYSTEM.AIO_BASIC]: {
                // BLU-249:
                // Manually configured AIO Basic needs special handling for machine room smoke detection
                // because unlike L-AIO it doesn't have a dedicated port for it.
                // Instead a P&G point detector is inserted into the shaft port line.
                when: { value: { ctx: 'basicValues', key: 'machineRoom' }, equalsAny: [
                    { constant: MACHINE_ROOM.UP },
                    { constant: MACHINE_ROOM.DOWN },
                ]},
                exec: { builtin: {
                    name: 'addProductByKey',
                    args: [PRODUCT_KEY.PD_RJ_AIO_PG, 1],
                }}
            },
            [SYSTEM.L_AIO]: {
                // BK-3468 - Rauchmelder in Zusammenhang mit Maschinenraum
                // Add the special machine room point detector if a machine room is present
                // or if additionalSmokeDetector is requested and the smokeDetection is not POINTDETECTOR
                when: { or: [
                    { value: { ctx: 'basicValues', key: 'machineRoom' }, equalsAny: [
                        { constant: MACHINE_ROOM.UP },
                        { constant: MACHINE_ROOM.DOWN },
                    ]},
                    { and: [
                        { value: { ctx: 'basicValues', key: 'machineRoom' }, '==': { constant: MACHINE_ROOM.NO } },
                        { ctx: 'basicValues', key: 'additionalSmokeDetector' },
                        { value: { ctx: 'configValues', key: 'smokeDetection' }, equalsAny: [
                            { constant: SMOKE_DETECTION.SDLF1 },
                            { constant: SMOKE_DETECTION.RAS },
                        ]},
                    ]}
                ]},
                exec: {
                        builtin: {
                        name: 'addProductByKey',
                        args: [PRODUCT_KEY.RAUCHMELDER_MASCHINENRAUM, 1],
                        }
                },
            }
        },
    },
    [PRODUCT_KEY.MAIN_LEVEL_SMOKE_DETECTOR]: {
        stage: 'config',
        when: { ctx: 'basicValues', key: 'mainLevelSmokeDetector' },
        exec: {
            builtin: {
                name: 'addProductByKey',
                args: [PRODUCT_KEY.MAIN_LEVEL_SMOKE_DETECTOR, 1],
            }
        },
    },

    // Rules for 8pol Interface Adapter Set
    // Rule 34.
    [PRODUCT_KEY.eightPinInterfaceAdapterSet + '-nrwgDelivered']: {
        // Pre-installed NRWG needs adapter to RJ45
        stage: 'config',
        when: { ctx: 'basicValues', key: 'nrwgDelivered' },
        exec: { builtin: {
            name: 'addProductByKey',
            args: [PRODUCT_KEY.eightPinInterfaceAdapterSet, 1],
        }}
    },

    [PRODUCT_KEY.eightPinInterfaceAdapterSet + '-systemIntegration']: {
        // System integration (BMZ/GLA) needs adapter to RJ45
        stage: 'config',
        when: { ctx: 'basicValues', key: 'systemIntegration' },
        exec: { builtin: {
            name: 'addProductByKey',
            args: [PRODUCT_KEY.eightPinInterfaceAdapterSet, 1],
        }}
    },

    [PRODUCT_KEY.eightPinInterfaceAdapterSet + '-eightPinInterfaceAdapterSet']: {
        // Manual selection (moved here to unify differentiation between systems)
        stage: 'config',
        when: { ctx: 'configValues', key: 'eightPinInterfaceAdapterSet' },
        exec: { builtin: { name: 'addProductByKey', args: [PRODUCT_KEY.eightPinInterfaceAdapterSet, 1] }}
    },

    [PRODUCT_KEY.RT_45_RJ]: makeRuleForProductFromConfigFormValueQuantity(PRODUCT_KEY.RT_45_RJ),
    [PRODUCT_KEY.RT_45_LRJ]: makeRuleForProductFromConfigFormValueQuantity(PRODUCT_KEY.RT_45_LRJ),
    [PRODUCT_KEY.RJ_45_SPLITTER]: makeRuleForProductFromConfigFormValueQuantity(PRODUCT_KEY.RJ_45_SPLITTER),
    [PRODUCT_KEY.KEY_SWITCH_LED]: makeRuleForProductFromConfigFormValueQuantity(PRODUCT_KEY.KEY_SWITCH_LED),
    [PRODUCT_KEY.VENTILATION_TIMER]: makeRuleForProductFromConfigFormValueFixed(PRODUCT_KEY.VENTILATION_TIMER),
    [PRODUCT_KEY.HUMIDITY_SENSOR]: makeRuleForProductFromConfigFormValueFixed(PRODUCT_KEY.HUMIDITY_SENSOR),
    [PRODUCT_KEY.SIREN_WITH_KEY_SWITCH]: makeRuleForProductFromConfigFormValueFixed(PRODUCT_KEY.SIREN_WITH_KEY_SWITCH),

    // Rules for FM-2M (function module for additional ventilation component motors)
    [PRODUCT_KEY.FM_2_M]: {
        stage: 'post',
        exec: ({ basicValues: { machineRoom }, addProductByKey }, _, { selectedProducts }) => {
            // BK-3450 - Neues Zubehör-Produkt FM-2M
            // Add a FM-2M for each ventilation component except the first one (if no machine room) or two (if machine room)
            const ventComponents = selectedProducts.filter(p => p.productTypeId === PRODUCT_TYPE.NRWG);
            const ventCompCount = ventComponents.map(p => p.quantity).reduce((a, e) => a + e, 0);

            const excludedCount = machineRoom === MACHINE_ROOM.NO ? 1 : 2;
            const quantity = Math.max(ventCompCount - excludedCount, 0);

            addProductByKey(PRODUCT_KEY.FM_2_M, quantity);
        }
    },
    [PRODUCT_KEY.FM_2_M + '-custom']: {
        stage: 'custom',
        when: { value: { ctx: 'costCalculationValues', key: 'fm-2m-custom' }, is: 'not empty'},
        exec: ({ addProductByKey }, _, { costCalculationValues, selectedProducts }) => {
            // BK-3450 - Neues Zubehör-Produkt FM-2M
            // Override FM-2M quantity with custom value if present
            const fm2m = selectedProducts.find(x => x.productKey === PRODUCT_KEY.FM_2_M);
            if (fm2m) {
                fm2m.estimatedQuantity = fm2m.quantity;
                fm2m.quantity = costCalculationValues['fm-2m-custom'];
            } else {
                addProductByKey(PRODUCT_KEY.FM_2_M, costCalculationValues['fm-2m-custom']);
            }
        }
    },

    [PRODUCT_KEY.RJ_45_SPLITTER + '-accessories']: {
        stage: 'post',
        exec: ({ addProductByKey }, _, { selectedProducts }) => {
            // For the accumulate count `n` of
            // - key switches,
            // - thermostats,
            // - timers,
            // - humidity sensors,
            // add `n - 1` splitters.

            const count = selectedProducts
                .filter(p => [
                    PRODUCT_KEY.KEY_SWITCH_LED,
                    PRODUCT_KEY.ADDITIONAL_THERMOSTAT,
                    PRODUCT_KEY.VENTILATION_TIMER,
                    PRODUCT_KEY.HUMIDITY_SENSOR,
                ].includes(p.productKey))
                .map(p => p.quantity)
                .reduce((a, b) => a + b, 0);

            if (count > 1) {
                addProductByKey(PRODUCT_KEY.RJ_45_SPLITTER, count - 1);
            }
        },
    },

    [PRODUCT_KEY.BATTERY_PACK + '-topUp']: {
        stage: 'post',
        when: [
            // Battery packs are incompatible with system == AIO-Basic;
            // ensure all cases handled here are either
            // (a) mutually exclusive with AIO-Basic, or
            // (b) approved to be fine without battery packs.
            // - nrwgDelivered:         Excludes AIO-Basic.
            // - smokeDetection == RAS: The setup will not be EN 54 compliant,
            //                          but this is only relevant with systemIntegration (BMA).
            //                          Explicitly permitted. (BLU-270)
            { value: { builtin: 'getSystem' }, '!=': { constant: SYSTEM.AIO_BASIC }},
            { or: [
                { ctx: 'basicValues', key: 'nrwgDelivered' },
                { value: { ctx: 'configValues', key: 'smokeDetection' }, '==': { constant: SMOKE_DETECTION.RAS } },
            ]},
        ],
        exec: { builtin: { name: 'topUpProductByKey', args: [PRODUCT_KEY.BATTERY_PACK, 2] }},
    },

    // Rule 36.
    [PRODUCT_KEY.SMOKE_TEST_SPRAY]: {
        stage: 'post',
        when: [
            // POINTDETECTORs and RAS require smoke test spray, if montage is not included
            { value: { ctx: 'costCalculationValues', key: 'montageIncluded' }, '==': { constant: false } },
            (_1, _2, { selectedProducts }) => selectedProducts.some(p => (
                [PRODUCT_GROUP.POINTDETECTOR, PRODUCT_GROUP.RAS].includes(p.productGroupId) ||
                [PRODUCT_KEY.RAUCHMELDER_MASCHINENRAUM, PRODUCT_KEY.MAIN_LEVEL_SMOKE_DETECTOR].includes(p.productKey)
            )),
        ],
        exec: { builtin: { name: 'topUpProductByKey', args: [PRODUCT_KEY.SMOKE_TEST_SPRAY, 1] }},
    },

    ...ventCompFlow,

    // rule 34.: Add 8pol Interface Adapter Set for S9 or Tairmo Sets
    [PRODUCT_KEY.eightPinInterfaceAdapterSet + '-ventcomp-S9-Tairmo']: {
        stage: 'post',
        exec: ({ addProductByKey }, _, { selectedProducts }) => {
            const PRODUCT_GROUPS_REQUIRING_8PIN_INTERFACE_ADAPTER_SET = [PRODUCT_GROUP.THERMOS9, PRODUCT_GROUP.THERMOTAIRMO];

            selectedProducts
                .filter(p => PRODUCT_GROUPS_REQUIRING_8PIN_INTERFACE_ADAPTER_SET.includes(p.productGroupId))
                .map(p => p.quantity)
                .filter(quantity => quantity > 0)
                .forEach(quantity => addProductByKey(PRODUCT_KEY.eightPinInterfaceAdapterSet, quantity));
        },
    },

    // rule 8.: Battery pack
    // If we have any QUIET components, two battery packs are required.
    // This should only top-up to two bps (i.e. don't stack with other requirements for bps).
    [PRODUCT_KEY.BATTERY_PACK + '-topUp-ventcomp-quiet']: {
        stage: 'post',
        when: ({ productData: { characteristics }}, _, { selectedProducts }) => {
            const quietProductGroupIds = characteristics
                .filter(x => x.characteristicKey === CHARACTERISTIC_KEY.QUIET)
                .map(x => x.productGroupId);

            return selectedProducts.findIndex(p => p.quantity > 0 && quietProductGroupIds.includes(p.productGroupId)) !== -1;
        },
        exec: { builtin: { name: 'topUpProductByKey', args: [PRODUCT_KEY.BATTERY_PACK, 2] }},
    },
};

export default defaultFlow;

function makeRuleForProductFromConfigFormValueFixed(productKey: PRODUCT_KEY, quantity: number = 1): RootBuilderRule {
    return {
        stage: 'config',
        when: { ctx: 'configValues', key: productKey },
        exec: { builtin: { name: 'addProductByKey', args: [productKey, quantity] }},
    };
}

function makeRuleForProductFromConfigFormValueQuantity(productKey: PRODUCT_KEY): RootBuilderRule {
    return {
        stage: 'config',
        when: { value: { ctx: 'configValues', key: productKey }, '>': { constant: 0 }},
        exec: ({ configValues, addProductByKey }) => addProductByKey(productKey, configValues[productKey]),
    };
}
