import * as React from 'react';

import { Markdown } from '../../shared/components/Markdown';
import { RestrictLanguages, useLanguage } from '../../localization/hook';

/* eslint import/no-webpack-loader-syntax: off */
import dataProtectionDeclaration_de_md from '!!raw-loader!./DataProtectionDeclaration_de.md';
import dataProtectionDeclaration_en_md from '!!raw-loader!./DataProtectionDeclaration_en.md';
import { RestrictedLanguageMessage } from '../../localization/RestrictedLanguageMessage';


export const DataProtectionDeclaration: React.FC<{}> = () => {
    const language = useLanguage();
    const md = React.useMemo(() => getMdForLang(language), [language]);

    return <RestrictLanguages languages={['de', 'en']}>
        <RestrictedLanguageMessage/>
        <Markdown children={md}/>
    </RestrictLanguages>;
};

function getMdForLang(lang: string) {
    switch (lang) {
        case 'de':
            return dataProtectionDeclaration_de_md;
        case 'en':
        default:
            return dataProtectionDeclaration_en_md;
    }
}
