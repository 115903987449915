import * as React from 'react';
import {connect, useSelector} from 'react-redux';
import {createStructuredSelector} from "reselect";
import {Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm} from 'redux-form';
import {numericality, required, length} from 'redux-form-validators';
import {Button, Form, Grid, Table} from 'semantic-ui-react';
import sumBy from 'lodash-es/sumBy';

import {ReduxFormKeys} from '../../../form/ReduxFormKeys';
import {IApplicationState} from '../../../../shared/ApplicationState';
import {Spinner, SpinnerOpCode} from '../../../../shared/components/Spinner';
import {TabSection} from '../../../../shared/components/TabSection';
import {renderCheckbox, renderInput, renderSelect, renderTextArea} from '../../../../shared/components/FormControls';
import {FileUploader} from "../../../../shared/components/file-uploader/file-uploader";
import {FormErrorList, syncedErrorsToList} from "../../../../shared/components/error-list";
import { useLocalization } from '../../../../localization/hook';
import {isAdmin, isCustomer} from "../../../auth/PermissionService";

import {PROJECT_STATE} from "../../../project/ProjectModels";

import { useUser } from '../../../auth/User.hook';
import { useSettings } from '../../../settings/Settings.hook';
import { useProjectExport } from '../../../project-export/ProjectExport.hook';
import { useProjectFiles } from '../../../file/File.hook';
import { useReportingWithRedux } from '../../../reporting/Reporting.hook';

import DebugTable from '../DebugTable';
import { useCalculateCosts, useProjectV1 } from '../../calculation.hook';
import { COUNTRY, PROJECT_TYPE, REQUEST_TYPE } from '../../../project/lookup';
import {
    getCalculationState,
    getForm,
    isAllFormReady,
    isCostCalculationFormValid
} from "../../calculation.selector";
import { ICalculationInput } from '../../CalculationModels';
import { BasicInputsFormModel } from '../../models/FormModels';
import { getFormValuesDictionaryFromForms } from '../../CalculationService';
import { isMontagePossible } from '../../CalculationService/Montage';
import {
    BASIC_INPUTS_FORM_VALUES_SELECTOR,
    COST_CALCULATION_FORM_VALUES_SELECTOR
} from "../02-ConfigurationInputs/ConfigurationInputsForm";

import {SaveProjectModal} from './_SaveProjectModal';
import {CostCalculationList} from "./_CostCalculationList";


function useSpecialRemarks(
    { projectType, aioBasicSet }: Partial<Pick<BasicInputsFormModel, 'projectType'|'aioBasicSet'>>,
) {
    const { localize } = useLocalization();

    return React.useMemo(() => {
        const remarks: string[] = [];

        if (projectType === PROJECT_TYPE.MODERNIZED && !!aioBasicSet) {
            // BLU-314
            remarks.push(localize('info.modernized_check_suitable'));
        }

        return remarks;
    }, [
        localize,
        projectType,
        aioBasicSet,
    ]);
}

const CostCalculationForm: React.FC<Props> = props => {
    const {
        isAllFormReady,
        basicInputFormValues,
        costCalculationFormValues,
        syncedErrors,
        isCostCalculationFormValid,
        isActive,
    } = props;

    const { settings, settingsError, settingsLoading } = useSettings();
    const { user, userError, userLoading } = useUser();

    const { 
        project,
        projectError,
        projectLoading,
        projectIsUpdating,
        updateProject
    } = useProjectV1();

    const form = useSelector(getForm);
    const calculationState = useSelector(getCalculationState);

    const {
        fileList,
        fileLoading,
        fileError,
        fileErrorList,
        fetchFileList,
        resetFileList,
        deleteFile,
        uploadFile,
        downloadFile,
    } = useProjectFiles(isActive ? project?.id : null);

    React.useEffect(() => {
        if (!isActive) return;
        fetchFileList();
        return () => {
            resetFileList();
        };
    }, [isActive, fetchFileList, resetFileList]);

    const calculationInput: ICalculationInput = React.useMemo(() => {
        if (!isActive || !calculationState.isReadyForCalculation) {
            return null;
        };

        return {
            formValues: getFormValuesDictionaryFromForms(form),
            selectedProducts: calculationState.selectedProducts,
            selectedAIOBasicSet: calculationState.selectedAIOBasicSet,
            selectedNonStandardComponents: calculationState.nonStandardComponents,
            owningCompanyId: project?.owningCompanyId,
        }
    }, [
        isActive,
        calculationState.isReadyForCalculation,
        form, calculationState.selectedProducts,
        calculationState.selectedAIOBasicSet, calculationState.nonStandardComponents,
        project?.owningCompanyId
    ]);

    const {
        costs,
        costsLoading,
        costsError,
        fetchCosts,
        resetCosts,
    } = useCalculateCosts(calculationInput);

    React.useEffect(() => {
        if (!isActive) return;

        fetchCosts();
        return () => {
            resetCosts();
        };
    }, [isActive, fetchCosts, resetCosts]);

    const reporting = useReportingWithRedux();

    const { localize, enumToLookup, language } = useLocalization();

    const {
        error: pdfError,
        fetch: pdfFetch,
        loading: pdfLoading
    } = useProjectExport('pdf', project, form, calculationState, language);

    const {
        error: xlsxError,
        fetch: xlsxFetch,
        loading: xlsxLoading
    } = useProjectExport('xlsx', project, form, calculationState, language);

    const specialRemarks = useSpecialRemarks({
        projectType: basicInputFormValues?.projectType,
        aioBasicSet: basicInputFormValues?.aioBasicSet,
    });

    const formErrorList = React.useMemo(
        () => syncedErrorsToList(syncedErrors).map(({ key, message }) => ({ key: 'field.' + key, message })),
        [syncedErrors]
    );

    const projectStateOptions = React.useMemo(
        () => enumToLookup(Object.values(PROJECT_STATE), 'projectState'),
        [enumToLookup]
    );

    if (!settings) return <Spinner operation={SpinnerOpCode.C4_SETTINGS} loading={settingsLoading} error={settingsError}/>;
    if (!user) return <Spinner operation={SpinnerOpCode.C4_USER} loading={userLoading} error={userError}/>;
    if (!isAllFormReady) return <Spinner operation={SpinnerOpCode.C4_FORM_READY} loading/>;
    if (!project) return <Spinner operation={SpinnerOpCode.C4_PROJECT} loading={projectLoading} error={projectError}/>;

    const isCostsValid = !!costs && !costsLoading && !costsError;

    const isMontageValid = isCostsValid && (
        !costCalculationFormValues.montageIncluded ||
        isMontagePossible(basicInputFormValues, costCalculationFormValues, costs)
    );

    return (
        <>
            <TabSection>
                <Form>
                    {basicInputFormValues.country === COUNTRY.DE && <Form.Group>
                        <Field
                            style={{alignSelf: 'center'}}
                            component={renderCheckbox}
                            name="montageIncluded"
                            label={localize('field.montageIncluded')}
                        />
                        <Field
                            style={{alignSelf: 'center'}}
                            component={renderInput}
                            name="montageBuildingLocation"
                            inputStyle={{padding: 0}}
                            hideError={true}
                            size='small'
                            validate={costCalculationFormValues.montageIncluded ? [
                                required(),
                                // German post code (PLZ) rules:
                                numericality({ int: true, msg: localize('is not a number') }),
                                length({ min: 4, max: 5, msg: localize('has to be 4 or 5 characters long') })
                            ] : []}
                        />
                    </Form.Group>}

                    <p className="montage-error">
                        {
                            !isCostCalculationFormValid &&
                            <FormErrorList errorList={formErrorList} displayMode="plain"/>
                        }
                        {
                            isCostsValid && isCostCalculationFormValid && !isMontageValid &&
                            localize('info.montage_calculation_unavailable')
                        }
                    </p>
                </Form>

                <Spinner segment operation={SpinnerOpCode.C4_COSTS} loading={costsLoading} error={costsError}/>
                {
                    isCostsValid && <CostCalculationList
                        calculateCostsList={costs}
                        specialRemarks={specialRemarks}
                        settings={settings}
                    />
                }
                {
                    isCostsValid && isMontageValid &&
                    <p>
                        <Button secondary fluid loading={pdfLoading} onClick={() => pdfFetch()}>
                            {localize('button.downloadPDF')}
                        </Button>
                    </p>
                }
                {
                    isCostsValid && isMontageValid &&
                    <Button secondary fluid loading={xlsxLoading} onClick={() => xlsxFetch()}>
                        {localize('button.downloadExcel')}
                    </Button>
                }
                <FormErrorList displayMode="single" errorList={pdfError}/>
                <FormErrorList displayMode="single" errorList={xlsxError}/>
            </TabSection>
            <Grid columns={2}>
                <Grid.Column verticalAlign="middle">
                    <h2>{localize('documentUpload.title')}</h2>
                    <span>{localize('documentUpload.text')}</span>
                </Grid.Column>
                <Grid.Column>
                    <TabSection>
                        <Form>
                            <Spinner segment='small' operation={SpinnerOpCode.C4_FILES} loading={fileLoading} error={fileError}/>
                            {
                                fileList?.length > 0 &&
                                <Table celled striped>
                                    <Table.Header>
                                        <Table.Row className="boldRow midBlueRow">
                                            <Table.HeaderCell>
                                                {localize('documentUpload.table.column.name')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="right" collapsing>
                                                {localize('documentUpload.table.column.action')}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {fileList.map(file =>
                                            <Table.Row key={file.id}
                                                        className="non-standard-component-table-row">
                                                <Table.Cell>
                                                    <a className="file-link" data-cy="file-link"
                                                        onClick={_ => downloadFile(file.name)} target="_blank"
                                                    >
                                                        {file.name}
                                                    </a>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center" verticalAlign="top">
                                                    <Button
                                                        data-cy="file-delete"
                                                        className="remove-button"
                                                        icon="remove"
                                                        disabled={false}
                                                        onClick={_ => deleteFile(file.name)}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            }

                            {
                                project.state !== PROJECT_STATE.COMPLETED &&
                                <div className="file-upload-area">
                                    <FileUploader onFileChanged={(file, _data) => uploadFile(file)}/>
                                </div>
                            }
                        </Form>
                        <FormErrorList displayMode="single" errorList={fileErrorList}/>
                    </TabSection>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                    <h2>{localize('projectSubmit.title')}</h2>
                    <span>{localize('projectSubmit.text')}</span>
                </Grid.Column>
                <Grid.Column>
                    {
                        project.state !== PROJECT_STATE.COMPLETED &&
                        <TabSection>
                            <Form>
                                <div style={{padding: '0 0 1em 0'}}>
                                    { basicInputFormValues.projectType === 'MODERNIZED'
                                        && basicInputFormValues.installationPosition === 'V'
                                        && basicInputFormValues.weathershelter &&
                                        <p>{localize('info.modernized_weathershelter')}</p>
                                    }
                                </div>
                                <Field name="remarks"
                                    component={renderTextArea}
                                    rows={5}
                                    style={{resize: 'none'}}
                                    placeholder={localize('field.remarks.placeholder')}
                                />
                                <FormErrorList errorList={formErrorList}/>
                                <Field name="requestType"
                                    component={renderSelect}
                                    options={enumToLookup(Object.values(REQUEST_TYPE), 'REQUEST_TYPE')}
                                />
                                <Button primary fluid
                                        disabled={!isCostCalculationFormValid || project.state === PROJECT_STATE.SUBMITTED || reporting.submitLoading || !isCostsValid || !isMontageValid || costCalculationFormValues.requestType == null}
                                        loading={reporting.submitLoading}
                                        onClick={() => reporting.submit()}>
                                    {localize('projectSubmit.button.' + (project.state !== PROJECT_STATE.SUBMITTED ? 'submit' : 'submitted'))}
                                </Button>
                                <FormErrorList displayMode="single" errorList={reporting.submitError}/>
                            </Form>
                        </TabSection>
                    }
                </Grid.Column>
                <Grid.Column verticalAlign="middle"/>
                <Grid.Column>
                    <TabSection>
                        <Form>
                            {
                                isAdmin(user) &&
                                <>
                                    <p>
                                        {localize('projectManage.lastReportingAt') + ': '}
                                        {!!project.lastReportingAt
                                            ? (new Date(project.lastReportingAt)).toLocaleDateString('de-DE')
                                            : localize('never')
                                        }
                                    </p>
                                    <Field
                                        component={renderSelect}
                                        label={localize('projectState') + '*'}
                                        name="projectState"
                                        options={projectStateOptions}
                                        validate={[required()]}
                                    />
                                    {
                                        costCalculationFormValues.projectState === PROJECT_STATE.OFFER_CREATED &&
                                        <Field
                                            component={renderInput}
                                            label={localize('projectManage.offerNumber')}
                                            name="offerNumber"
                                            options={projectStateOptions}
                                        />
                                    }
                                    {
                                        costCalculationFormValues.projectState === PROJECT_STATE.ORDER_RECEIVED &&
                                        <Field
                                            component={renderInput}
                                            label={localize('projectManage.orderNumber')}
                                            name="orderNumber"
                                            options={projectStateOptions}
                                        />
                                    }
                                    <FormErrorList errorList={formErrorList}/>
                                    <SaveProjectModal disabled={!isCostCalculationFormValid || costsLoading}/>
                                </>
                            }
                            {
                                isCustomer(user) && !isAdmin(user) &&
                                <>
                                    <p>
                                        {localize('projectState')}: {localize('projectState.' + project.state)} {(!project.state || project.state === PROJECT_STATE.DRAFT || project.state === PROJECT_STATE.NEW)}
                                    </p>
                                    {
                                        project.state === PROJECT_STATE.OFFER_CREATED && costCalculationFormValues.offerNumber &&
                                        <p>{localize('projectManage.offerNumber')}: {costCalculationFormValues.offerNumber}</p>
                                    }
                                    {
                                        project.state === PROJECT_STATE.ORDER_RECEIVED && costCalculationFormValues.orderNumber &&
                                        <p>{localize('projectManage.orderNumber')}: {costCalculationFormValues.orderNumber}</p>
                                    }
                                    {
                                        (!project.state || project.state === PROJECT_STATE.DRAFT || project.state === PROJECT_STATE.NEW) &&
                                        <div>
                                            <FormErrorList errorList={formErrorList}/>
                                            <Button secondary fluid
                                                    disabled={!isCostCalculationFormValid || costsLoading}
                                                    loading={projectIsUpdating}
                                                    onClick={e => updateProject(project.id, PROJECT_STATE.DRAFT)}
                                            >{localize('projectManage.button.save')}</Button>
                                        </div>
                                    }
                                </>
                            }
                        </Form>
                    </TabSection>
                </Grid.Column>
            </Grid>

            

            {costs && costs.cablingInfoList && (
                <DebugTable title={localize('debugTable.cables.title')} columns={[
                    { key: "category" },
                    { key: "4m"       },
                    { key: "6m"       },
                    { key: "12m"      },
                ].map(c => ({ ...c, header: localize('debugTable.cables.column.' + c.key) }))}
                rows={costs.cablingInfoList.map(cable => ({ key: cable.category, cols: [
                    localize("cabling.category." + cable.category),
                    String(cable.cable4m),
                    String(cable.cable6m),
                    String(cable.cable12m),
                ]}))} bottomRow={{ key: "total", cols: [
                    localize('debugTable.cables.total'),
                    String(sumBy(costs.cablingInfoList, info => info.cable4m)),
                    String(sumBy(costs.cablingInfoList, info => info.cable6m)),
                    String(sumBy(costs.cablingInfoList, info => info.cable12m)),
                ]}} />
            )}

            {isAdmin(user) && costs?.montageCosts?.montageInfoList?.length > 0 && (
                <DebugTable title={localize('debugTable.montage.title')} columns={[
                    { key: "products" },
                    { key: "montage"  },
                ].map(c => ({ ...c, header: localize('debugTable.montage.column.' + c.key) }))}
                rows={costs.montageCosts.montageInfoList.map((p,i) => ({ key: i, cols: [
                    <>
                        {p.title &&
                            <div>{p.title}</div>
                        }
                        {p.products && p.products.map(p2 =>
                            <div key={p2.id}>{p2.quantity !== 1 ? p2.quantity + ' x ' + p2.description[language] : p2.description[language]}</div>
                        )}
                    </>,
                    <>
                        {p.quantity !== 1 && `${p.quantity} x ${p.listPrice} € = `}
                        {p.totalPrice} €
                    </>,
                ]}))} bottomRow={{ key: "total", cols: [
                    localize('debugTable.montage.total'),
                    String(sumBy(costs.montageCosts.montageInfoList, info => info.totalPrice)),
                ]}} />
            )}

            {isAdmin(user) && costs?.montageLocationCosts?.montageInfoList?.length > 0 && (
                <DebugTable title={localize('debugTable.montageLocation.title')} columns={[
                    { key: "product" },
                    { key: "price"   },
                ].map(c => ({ ...c, header: localize('debugTable.montageLocation.column.' + c.key) }))}
                rows={costs.montageLocationCosts.montageInfoList.map((p,i) => ({ key: i, cols: [
                    <>
                        {p.title &&
                            <div>{p.title}</div>
                        }
                        {p.products && p.products.map(p2 =>
                            <div key={p2.id}>{p2.quantity !== 1 ? p2.quantity + ' x ' + p2.description[language] : p2.description[language]}</div>
                        )}
                    </>,
                    p.quantity !== 1 ? p.quantity + ' x ' + p.listPrice + ' € = ' + p.totalPrice + ' €' : p.totalPrice + ' €',
                ]}))} bottomRow={{ key: "total", cols: [
                    localize('debugTable.montageLocation.total'),
                    String(sumBy(costs.montageLocationCosts.montageInfoList, info => info.totalPrice)),
                ]}} />
            )}
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    isAllFormReady,
    isCostCalculationFormValid,
    syncedErrors: getFormSyncErrors(ReduxFormKeys.costCalculationForm),

    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,
    costCalculationFormValues: getFormValues(ReduxFormKeys.costCalculationForm) as COST_CALCULATION_FORM_VALUES_SELECTOR,

    selectedProducts: (state: IApplicationState) => state.calculationState?.selectedProducts,
    nonStandardComponents: (state: IApplicationState) => state.calculationState?.nonStandardComponents,
});

export default connect(mapStateToProps)(
    reduxForm<{}, OwnProps & ReduxProps>({
        form: ReduxFormKeys.costCalculationForm,
        touchOnChange: true,
        touchOnBlur: true,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(CostCalculationForm)
)

type OwnProps = {
    isActive: boolean;
};
type ReduxProps = ReturnType<typeof mapStateToProps>;
type ReduxFormProps = InjectedFormProps<{}, OwnProps & ReduxProps>;
type Props = OwnProps & ReduxProps & ReduxFormProps;
