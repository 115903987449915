import * as React from 'react';

import { Markdown } from '../../shared/components/Markdown';
import { RestrictLanguages, useLanguage } from '../../localization/hook';
import { RestrictedLanguageMessage } from '../../localization/RestrictedLanguageMessage';

/* eslint import/no-webpack-loader-syntax: off */
import legalNotice_de_md from '!!raw-loader!./LegalNotice_de.md';
import legalNotice_en_md from '!!raw-loader!./LegalNotice_en.md';


export const LegalNotice: React.FC<{}> = () => {
    const language = useLanguage();
    const md = React.useMemo(() => getMdForLang(language), [language]);

    return <RestrictLanguages languages={['de', 'en']}>
        <RestrictedLanguageMessage/>
        <Markdown children={md}/>
    </RestrictLanguages>;
};

function getMdForLang(lang: string) {
    switch (lang) {
        case 'de':
            return legalNotice_de_md;
        case 'en':
        default:
            return legalNotice_en_md;
    }
}
