import React from "react";

import { useConfig } from "../../configProvider";
import { errorToList } from "../../shared/components/error-list";
import { useFetchOnce } from "../../shared/useFetchOnce.hook";

import { useAuthTokenContext } from "../auth/AuthService";

import { ProjectExportApiV1Client } from "./ProjectExportApiV1Client";


export function useProjectExport(...params: Parameters<ProjectExportApiV1Client['download']>) {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new ProjectExportApiV1Client(apiUrl['project-export-v1'], getToken), [apiUrl, getToken]);

    const exportGetter = React.useCallback(() => {
        if (params[1] == null || params[2] == null || params[3] == null) return;
        return client.download(...params);
    }, [client, ...params]);

    const { error, fetch, loading } = useFetchOnce(exportGetter);

    return React.useMemo(() => ({
        error: errorToList(error),
        fetch,
        loading,
    }), [
        error,
        fetch,
        loading,
    ]);
}
