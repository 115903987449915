import * as React from 'react';


interface FetchOnceState<T> {
    value: T|null;
    error: Error|null;
    token: Symbol|null;
}
const intitialState = <FetchOnceState<any>>{
    value: null,
    error: null,
    token: null,
};

const actions = {
    reset() {
        return <const>{ type: 'reset' };
    },
    fetchStart(token: Symbol) {
        return <const>{ type: 'fetchStart', token };
    },
    fetchError(token: Symbol, error: Error) {
        return <const>{ type: 'fetchError', token, error };
    },
    fetchSuccess<T>(token: Symbol, value: T) {
        return <const>{ type: 'fetchSuccess', token, value };
    },
}
type Action = ReturnType<typeof actions[keyof typeof actions]>;

/** Call getter() surrounded by action dispatches */
function runFetch<T>(dispatch: React.Dispatch<Action>, getter: () => Promise<T>) {
    const token = Symbol();

    dispatch(actions.fetchStart(token));

    getter().then(
        value => dispatch(actions.fetchSuccess(token, value)),
        error => dispatch(actions.fetchError(token, error))
    )
}

function reducer<T>(state: FetchOnceState<T>, action: Action): FetchOnceState<T> {
    console.debug('reducer', state, action);
    switch (action?.type) {
        case 'reset':
            return {
                ...intitialState,
            }
        case 'fetchStart':
            if (state.token !== null) {
                return state;
            }

            return {
                ...state,
                token: action.token,
            }
        case 'fetchError':
            if (state.token !== action.token){
                return state;
            }

            return {
                ...intitialState,
                error: action.error,
            }
        case 'fetchSuccess':
            if (state.token !== action.token){
                return state;
            }

            return {
                ...intitialState,
                value: action.value as T,
            }
        default:
            return state;
    }
}


export interface UseFetchOnce<T> {
    /** The value once it was fetched; or null */
    value: T|null;
    /** The fetch error; or null */
    error: Error|null;
    /** The fetch operation is loading */
    loading: boolean;
    /** Start fetching the value; no-op after first invocation (until reset() is used) */
    fetch: () => void;
    /** Reset the state by wiping out the value and error, an ongoing fetch's result (value or error) will be discarded */
    reset: () => void;
}

export function useFetchOnce<T>(getter: () => Promise<T>): UseFetchOnce<T> {
    const [state, dispatch] = React.useReducer<React.Reducer<FetchOnceState<T>, Action>>(reducer, intitialState);

    const fetch = React.useCallback((): void => {
        runFetch(dispatch, getter);
    }, [dispatch, getter]);

    const reset = React.useCallback((): void => {
        dispatch(actions.reset());
    }, [dispatch]);

    return {
        value: state.value,
        error: state.error,
        loading: state.token !== null,
        fetch,
        reset,
    };
}
